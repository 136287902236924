@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Light.0109a530.eot");
  src: url("GothamPro-Light.f5efbbdd.woff2") format("woff2"), url("GothamPro-Light.867a3b5c.woff") format("woff"), url("GothamPro-Light.8f6ed96d.ttf") format("truetype"), url("GothamPro-Light.ff90a3d1.svg#GothamPro-Light") format("svg"), url("GothamPro-Light.0109a530.eot#iefix") format("embedded-opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-LightItalic.c0b5d90f.eot");
  src: url("GothamPro-LightItalic.6c7786a4.woff2") format("woff2"), url("GothamPro-LightItalic.89780e9b.woff") format("woff"), url("GothamPro-LightItalic.fc9ad269.ttf") format("truetype"), url("GothamPro-LightItalic.9158b91f.svg#GothamPro-LightItalic") format("svg"), url("GothamPro-LightItalic.c0b5d90f.eot#iefix") format("embedded-opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro.42a5d4bc.eot");
  src: url("GothamPro.9bf63435.woff2") format("woff2"), url("GothamPro.ad17387c.woff") format("woff"), url("GothamPro.4e9e382c.svg#GothamPro") format("svg"), url("GothamPro.42a5d4bc.eot#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Italic.65757841.eot");
  src: url("GothamPro-Italic.ff50fad9.woff2") format("woff2"), url("GothamPro-Italic.36431a07.woff") format("woff"), url("GothamPro-Italic.323d302e.ttf") format("truetype"), url("GothamPro-Italic.eee14a6c.svg#GothamPro-Italic") format("svg"), url("GothamPro-Italic.65757841.eot#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Medium.6705baa6.eot");
  src: url("GothamPro-Medium.05d145b4.woff2") format("woff2"), url("GothamPro-Medium.ba6a5c88.woff") format("woff"), url("GothamPro-Medium.0092f429.ttf") format("truetype"), url("GothamPro-Medium.26b0c63e.svg#GothamPro-Medium") format("svg"), url("GothamPro-Medium.6705baa6.eot#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-MediumItalic.007d512c.eot");
  src: url("GothamPro-MediumItalic.56bf0889.woff2") format("woff2"), url("GothamPro-MediumItalic.a37e9a50.woff") format("woff"), url("GothamPro-MediumItalic.9a77545d.ttf") format("truetype"), url("GothamPro-MediumItalic.a683cdd9.svg#GothamPro-MediumItalic") format("svg"), url("GothamPro-MediumItalic.007d512c.eot#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Bold.9a704f22.eot");
  src: url("GothamPro-Bold.4f67de7b.woff2") format("woff2"), url("GothamPro-Bold.1fd4af52.woff") format("woff"), url("GothamPro-Bold.0af21160.svg#GothamPro-Bold") format("svg"), url("GothamPro-Bold.9a704f22.eot#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-BoldItalic.efdf2f13.eot");
  src: url("GothamPro-BoldItalic.91f124bb.woff2") format("woff2"), url("GothamPro-BoldItalic.b02694b7.woff") format("woff"), url("GothamPro-BoldItalic.601772a4.ttf") format("truetype"), url("GothamPro-BoldItalic.9659fab4.svg#GothamPro-BoldItalic") format("svg"), url("GothamPro-BoldItalic.efdf2f13.eot#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Black.3ba4c0ef.eot");
  src: url("GothamPro-Black.6624dc08.woff2") format("woff2"), url("GothamPro-Black.398470ef.woff") format("woff"), url("GothamPro-Black.cc13c9a4.ttf") format("truetype"), url("GothamPro-Black.0f8b893f.svg#GothamPro-Black") format("svg"), url("GothamPro-Black.3ba4c0ef.eot#iefix") format("embedded-opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-BlackItalic.6354f7f9.eot");
  src: url("GothamPro-BlackItalic.15ce8fc5.woff2") format("woff2"), url("GothamPro-BlackItalic.aa9d3079.woff") format("woff"), url("GothamPro-BlackItalic.dd0fc947.ttf") format("truetype"), url("GothamPro-BlackItalic.620b582a.svg#GothamPro-BlackItalic") format("svg"), url("GothamPro-BlackItalic.6354f7f9.eot#iefix") format("embedded-opentype");
  font-weight: 800;
  font-style: italic;
}

:root, [data-bs-theme="greenbelt"] {
  --bs-primary: #278242;
  --bs-primary-light: #6ac17a;
  --bs-secondary: #898989;
  --bs-secondary-light: #cecece;
  --bs-body-color: #2b2b2b;
  --bs-font-sans-serif: "GothamPro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-heading: "GothamPro", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", "TeXGyreHerosCnBold", "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
  --bs-body-font-weight: 200;
  --hero-heading-font-family: "GothamPro";
  --hero-heading-font-weight: 600;
  --hero-line-height: 1.8rem;
}

.navbar {
  --bs-navbar-nav-link-padding-x: 1.2rem;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

a {
  color: var(--bs-primary);
}

a:hover {
  color: var(--bs-primary-light);
}

.text-primary-light {
  color: var(--bs-primary-light) !important;
}

.font-heading {
  font-family: var(--font-heading);
  font-weight: 500;
  font-stretch: condensed;
}

.text-primary {
  color: var(--bs-primary) !important;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

h1 {
  text-transform: uppercase;
  color: var(--bs-primary);
  font-weight: bold;
  font-family: var(--font-heading);
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 600;
  font-stretch: condensed;
}

h1.huge {
  font-size: 5rem;
}

@media (max-width: 768px) {
  h1.huge {
    font-size: 3rem;
  }
}

h2 {
  text-transform: uppercase;
  color: var(--bs-primary);
  font-weight: bold;
  font-family: var(--font-heading);
  margin-bottom: 1.4rem;
  font-weight: 600;
  font-stretch: condensed;
}

h3 {
  text-transform: uppercase;
  color: #676767;
  font-weight: bold;
  font-family: var(--font-heading);
  margin-bottom: 1rem;
  font-weight: 600;
  font-stretch: condensed;
}

h4 {
  text-transform: uppercase;
  color: var(--bs-primary);
  font-weight: bold;
  font-family: var(--font-heading);
  font-weight: 600;
  font-stretch: condensed;
}

h5 {
  text-transform: uppercase;
  color: #676767;
  font-weight: bold;
  font-family: var(--font-heading);
  font-weight: 600;
  font-stretch: condensed;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary-light);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
  --bs-btn-border-width: 0;
  --bs-btn-border-radius: 0;
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .475rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: normal;
  margin-left: 20px;
  position: relative;
}

.btn-primary:hover:before {
  background-color: var(--bs-primary-light);
  left: -10px;
}

.btn-primary:before {
  content: " ";
  background-color: var(--bs-primary-light);
  z-index: -1;
  width: 8px;
  height: 100%;
  transition: all .4s;
  display: block;
  position: absolute;
  top: 0;
  left: -16px;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-secondary-light);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-secondary);
  --bs-btn-disabled-border-color: var(--bs-secondary);
  --bs-btn-border-width: 0;
  --bs-btn-border-radius: 0;
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .475rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: normal;
  margin-left: 20px;
  position: relative;
}

.btn-secondary:hover:before {
  background-color: var(--bs-secondary-light);
  left: -10px;
}

.btn-secondary:before {
  content: " ";
  background-color: var(--bs-secondary-light);
  z-index: -1;
  width: 8px;
  height: 100%;
  transition: all .4s;
  display: block;
  position: absolute;
  top: 0;
  left: -16px;
}

.btn-white {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-bg: #fff;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-secondary-light);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-secondary);
  --bs-btn-disabled-border-color: var(--bs-secondary);
  --bs-btn-border-width: 0;
  --bs-btn-border-radius: 0;
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .475rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: normal;
  margin-left: 20px;
  position: relative;
}

.btn-white:hover:before {
  background-color: var(--bs-secondary-light);
  left: -10px;
}

.btn-white:before {
  content: " ";
  background-color: var(--bs-secondary-light);
  z-index: -1;
  width: 8px;
  height: 100%;
  transition: all .4s;
  display: block;
  position: absolute;
  top: 0;
  left: -16px;
}

.btn-tab-right {
  margin-left: 0;
  margin-right: 20px;
}

.btn-tab-right:before {
  left: auto;
  right: -16px;
}

.btn-tab-right:hover:before {
  left: auto;
  right: -10px;
}

.btn-lg {
  --bs-btn-padding-x: 3rem;
  --bs-btn-padding-y: 1.375rem;
  min-width: 400px;
}

.section {
  --section-padding-x: 0;
  --section-padding-y: 60px;
  padding: var(--section-padding-y) var(--section-padding-x);
}

.section img {
  max-width: 100%;
  height: auto;
}

.mw-800 {
  max-width: 800px;
}

.p-10 {
  padding: 3rem;
}

.pt-10 {
  padding-top: 3rem;
}

.bt-1 {
  border-top: 1px solid #000;
}

.be-1 {
  border-right: 1px solid #000;
}

.section-footer {
  --section-padding-x: 0;
  --section-padding-y: 0;
  border-top: 1px solid #000;
}

.section-footer a {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.section-footer a:hover {
  color: var(--bs-primary);
}

.section-footer .logo {
  width: 100%;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--bs-primary);
}

@media (max-width: 768px) {
  .carousel-item {
    height: 500px;
  }

  .carousel-item img {
    min-width: 100%;
    max-width: none;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: auto !important;
  }
}

.section-image-split {
  --split-width: 50%;
  --content-padding-x: 3vw;
  --content-padding-y: 3rem;
  --section-padding-y: 0;
}

.section-image-split .content {
  padding: var(--content-padding-y) var(--content-padding-x);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.section-image-split .image {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section-image-split .image img {
  object-fit: cover;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.section-image-split .image img.height-fixed {
  min-height: auto;
}

.section-image-split .image .label {
  background: #fff;
  margin-top: 33%;
  padding: 10px;
  position: absolute;
  top: 50%;
}

@media (min-width: 768px) {
  .section-image-split {
    --section-padding-y: 60px;
    display: flex;
  }

  .section-image-split h2 {
    font-size: 3rem;
  }

  .section-image-split .image, .section-image-split .content {
    width: var(--split-width);
  }

  .section-image-split-right .image {
    order: 1;
  }

  .section-image-split .image img.height-fixed {
    margin-bottom: 10%;
  }

  .section-image-split .image .label {
    background: #fff;
    margin-top: 38%;
    padding: 10px;
    position: absolute;
    top: 50%;
  }
}

.main-hero {
  height: 85vh;
  margin-top: 100px;
}

.hero-text h1 {
  text-shadow: 2px 2px 2px #0000004d;
}

.hero .btn {
  font-family: var(--font-heading);
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 600;
  font-stretch: condensed;
}

.main-hero h1 {
  font-family: var(--hero-heading-font-family);
  font-weight: var(--hero-heading-font-weight);
}

@media (min-width: 768px) {
  .main-hero h1 {
    font-size: 6rem;
  }

  .hero h2 {
    font-size: 4rem;
  }
}

.row-image-overly {
  flex-wrap: wrap;
  margin: 0;
  display: flex;
}

.row-image-overly .col {
  flex: 1 0 50%;
  height: 600px;
  max-height: 80vh;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.row-image-overly .col:hover .overlay {
  opacity: 0;
}

.row-image-overly .col:hover .overlay-hidden {
  opacity: 1;
}

.row-image-overly .col h3 {
  color: #fff;
}

.row-image-overly .overlay {
  opacity: 1;
  z-index: 2;
  align-items: end;
  padding: 20px;
  transition: all .4s;
  display: flex;
  position: absolute;
  inset: 0;
}

.row-image-overly .overlay-hidden {
  opacity: 0;
  z-index: 2;
  background: #0006;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  padding: 20px;
  transition: all .4s;
  display: flex;
  position: absolute;
  inset: 0;
}

.row-image-overly img {
  width: auto;
  max-width: none;
  height: 100%;
}

@media (min-width: 768px) {
  .row-image-overly .col {
    flex: 1 0 33%;
  }
}

@media (min-width: 1268px) {
  .row-image-overly {
    flex-wrap: nowrap;
    display: flex;
  }

  .row-image-overly .col {
    flex: 1 0 20%;
  }
}

/*# sourceMappingURL=index.2677367b.css.map */
