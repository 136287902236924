@import url('../fonts/gotham/gotham.css');

:root,
[data-bs-theme=greenbelt] { 
    --bs-primary: #278242;
    --bs-primary-light: #6AC17A;
    --bs-secondary: #898989;
    --bs-secondary-light: #CECECE;
    --bs-body-color: #2b2b2b;
    --bs-font-sans-serif: "GothamPro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --font-heading: "GothamPro", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
    --bs-body-font-weight: 200;

    --hero-heading-font-family: 'GothamPro';
    --hero-heading-font-weight: 600;
    --hero-line-height: 1.8rem;
}
.navbar {
    --bs-navbar-nav-link-padding-x: 1.2rem;
}
body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg); 
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a {
    color: var(--bs-primary);
}
a:hover {
    color: var(--bs-primary-light);
}
.text-primary-light {
    color: var(--bs-primary-light) !important;
}

.font-heading {
    font-family: var(--font-heading); 
    font-weight: 500; 
    font-stretch:condensed;
}
.text-primary {
    color: var(--bs-primary) !important;
}
.bg-primary {
    background-color: var(--bs-primary) !important;
}

h1 {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--bs-primary);
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
    font-size: 3rem;
    margin-bottom: 2rem;
}
h1.huge {
    font-size: 5rem;
}
@media (max-width: 768px ) {
    h1.huge {
        font-size: 3rem;
    }
}
h2 {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--bs-primary);
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
    margin-bottom: 1.4rem;
}
h3 {
    font-weight: bold;
    text-transform: uppercase;
    color: #676767;
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
    margin-bottom: 1rem;
}
h4 {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--bs-primary);
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
}
h5 {
    font-weight: bold;
    text-transform: uppercase;
    color: #676767;
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-primary-light);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-primary);
    --bs-btn-active-border-color: var(--bs-primary);
    --bs-btn-active-shadow: inset 0 3px 5px #00000020;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-primary);
    --bs-btn-disabled-border-color: var(--bs-primary);
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-padding-x: .75rem;
    --bs-btn-padding-y: .475rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: normal;
    position: relative;
    margin-left: 20px;
}
.btn-primary:hover:before  {
    left: -10px;
    background-color: var(--bs-primary-light);
}
.btn-primary:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: -16px;
    width: 8px;
    height: 100%;
    background-color: var(--bs-primary-light);
    z-index: -1;
    transition: ease all 400ms;
}
.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-secondary);
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-secondary-light);
    --bs-btn-hover-border-color: var(--bs-secondary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-secondary);
    --bs-btn-active-border-color: var(--bs-secondary);
    --bs-btn-active-shadow: inset 0 3px 5px #00000020;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-secondary);
    --bs-btn-disabled-border-color: var(--bs-secondary);
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-padding-x: .75rem;
    --bs-btn-padding-y: .475rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: normal;
    position: relative;
    margin-left: 20px;
}
.btn-secondary:hover:before  {
    left: -10px;
    background-color: var(--bs-secondary-light);
}
.btn-secondary:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: -16px;
    width: 8px;
    height: 100%;
    background-color: var(--bs-secondary-light);
    z-index: -1;
    transition: ease all 400ms;
}
.btn-white {
    --bs-btn-color: var(--bs-primary);
    --bs-btn-bg: #fff;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-secondary-light);
    --bs-btn-hover-border-color: var(--bs-secondary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-secondary);
    --bs-btn-active-border-color: var(--bs-secondary);
    --bs-btn-active-shadow: inset 0 3px 5px #00000020;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-secondary);
    --bs-btn-disabled-border-color: var(--bs-secondary);
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-padding-x: .75rem;
    --bs-btn-padding-y: .475rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: normal;
    position: relative;
    margin-left: 20px;
}
.btn-white:hover:before  {
    left: -10px;
    background-color: var(--bs-secondary-light);
}
.btn-white:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: -16px;
    width: 8px;
    height: 100%;
    background-color: var(--bs-secondary-light);
    z-index: -1;
    transition: ease all 400ms;
}
.btn-tab-right {
    margin-left: 0;
    margin-right: 20px;
}
.btn-tab-right:before {
    left: auto;
    right: -16px;
}
.btn-tab-right:hover:before  {
    left: auto;
    right: -10px;
}

.btn-lg {
    --bs-btn-padding-x: 3rem;
    --bs-btn-padding-y: 1.375rem;
    min-width: 400px;
}

.section {
    --section-padding-x: 0;
    --section-padding-y: 60px;
    padding: var(--section-padding-y) var(--section-padding-x);
}   
.section img {
    max-width: 100%;
    height: auto;
}

.mw-800 {
    max-width: 800px;
}
.p-10 {
    padding: 3rem;
}
.pt-10 {
    padding-top: 3rem;
}
.bt-1 {
    border-top: 1px solid #000;
}
.be-1 {
    border-right: 1px solid #000;
}
.section-footer {
    --section-padding-x: 0;
    --section-padding-y: 0;
    border-top: 1px solid #000;
}
.section-footer a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.section-footer a:hover {
    color: var(--bs-primary)
}

.section-footer .logo {
    width: 100%;
}

.carousel-indicators [data-bs-target] {
    background-color: var(--bs-primary);
}

@media (max-width: 768px ) {
    .carousel-item { 
        height: 500px;
    } 
    .carousel-item img { 
        height: 100%;
        width: auto !important;
        max-width: none;
        min-width: 100%;
        left: 50%;
        position: relative;
        transform:  translateX(-50%);
    } 
}


.section-image-split {
    --split-width: 50%;
    --content-padding-x: 3vw;
    --content-padding-y: 3rem;
    --section-padding-y: 0;
}
.section-image-split .content {
    padding: var(--content-padding-y) var(--content-padding-x);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section-image-split .image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.section-image-split .image img {
    flex-shrink: 0;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
}
.section-image-split .image img.height-fixed {
    min-height: auto;
}
.section-image-split .image .label {
    position: absolute;
    background: #fff;
    top: 50%;
    padding: 10px;
    margin-top: 33%;
}
@media (min-width: 768px ) {
    .section-image-split {
        display: flex;
        --section-padding-y: 60px;
    }
    .section-image-split h2 {
        font-size: 3rem;
        
    }
    .section-image-split .image,
    .section-image-split .content {
        width: var(--split-width);
    }

    .section-image-split-right .image {
        order: 1;
    }
    .section-image-split .image img.height-fixed {
        margin-bottom: 10%;
    }
    .section-image-split .image .label {
        position: absolute;
        background: #fff;
        top: 50%;
        padding: 10px;
        margin-top: 38%;
    }
}


.main-hero {
	height: 85vh;
    margin-top: 100px;
}
.hero-text h1 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.30);
}
.hero .btn {
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
    padding-left: 2rem;
    padding-right: 2rem;
}
.main-hero h1 {
	font-family: var(--hero-heading-font-family);
	font-weight: var(--hero-heading-font-weight);
}
@media (min-width: 768px ) {
	.main-hero h1 {
		font-size: 6rem;
	}
}
@media (min-width: 768px ) {
    .hero h2 {
        font-size: 4rem;    
    }
}

.row-image-overly {
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    .col {
        overflow: hidden;
        height: 600px;
        max-height: 80vh;
        position: relative;
        padding: 0;
        flex: 1 0 50%;

        &:hover {
            .overlay {
                opacity: 0;
            }
            .overlay-hidden {
                opacity: 1;
            }
        }

        h3 {
            color: #fff;
        }
    }
    .overlay {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        padding: 20px;
        display: flex;
        align-items: end;
        transition: ease all 400ms;
    }
    .overlay-hidden {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        padding: 20px;
        background: rgba(0, 0, 0, 0.40);
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: end;
        transition: ease all 400ms;
    }
    img {
        height: 100%;
        width: auto;
        max-width: none;
    }
}

@media (min-width: 768px ) {
    .row-image-overly .col {
        flex: 1 0 33%;
    }
}
@media (min-width: 1268px ) {
    .row-image-overly {
        display: flex;
        flex-wrap: nowrap;
    }
    .row-image-overly .col {
        flex: 1 0 20%;
    }
}